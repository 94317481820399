export const Constants = {
    firebaseConfig: {
        apiKey: "AIzaSyAi8babbkJkTkVwMQYHzgJueRxW_0ijn3I",
        authDomain: "hogarizarme.firebaseapp.com",
        databaseURL: "https://hogarizarme.firebaseio.com",
        projectId: "hogarizarme",
        storageBucket: "hogarizarme.appspot.com",
        messagingSenderId: "217129479836",
        appId: "1:217129479836:web:23827b9a2c7d8602d84759",
        measurementId: "G-PC8FPMQ330"
    },
    routes: {
        home: 'home',
        form: 'form'
    },
    collections: {
        stories: 'stories'
    },
    functions: {
        sendForm: 'sendForm'
    },
    mail: 'contacto@hogarizarme.com'
}