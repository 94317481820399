<!-- UP BUTTON -->
<div class="up-button" #upButton (click)="up()">
    <mat-icon svgIcon="arrow" [inline]="true"></mat-icon>
</div>

<!-- HEADER -->
<page-header></page-header>

<!-- MAIN -->
<div class="block main-block" id="main">
    <div class="inner-block">
        <div class="logo">
            <div class="engage">El orden y la alegría llegan para quedarse</div>
        </div>
        <div class="sticker blue-fluff">
            <mat-icon svgIcon="fluff" [inline]="true"></mat-icon>
        </div>
    </div>
</div>

<!-- INTRODUCTION -->
<div class="block introduction" id="introduction">
    <div class="inner-block">
        <div class="font-body1 text">
            Porque no todos pensamos, sentimos, ni vivimos del mismo modo.<br>
            Porque no todos tenemos los mismos sueños,<br *ngIf="!smallScreen"> ni los mismos modos de vivir.<br>
            Hogariza tu casa, adáptala a ti, tu familia y tu momento presente.<br> 
            Que cada rincón de casa sea un reflejo de lo que eres,<br *ngIf="!smallScreen"> y un privilegio para compartir (o no).
        </div>
    </div>
</div>

<!-- WHAT -->
<div class="block what" id="what">
    <div class="inner-block">
        <div class="title">
            <div class="font-h3">¿Qué es lo que hacemos?</div>
            <div class="font-h2">Servicio personalizado de organización y ordenación de espacios</div>
            <page-link [link]="whatLink" (clicked)="mail('Estoy interesado en Hogarizarme - Quiero saber más')"></page-link>
        </div>
        <div class="cards">
            <div class="column1">
                <card [card]="whatCards[0]" class="card0"></card>
            </div>
            <div class="column2">
                <card [card]="whatCards[1]" class="card1"></card>
            </div>
            <div class="column3">
                <card [card]="whatCards[2]" class="card2"></card>
                <card [card]="whatCards[3]"></card>
            </div>
            <div class="column4">
                <card [card]="whatCards[4]" class="card4"></card>
            </div>
            <page-link [link]="whatLink" (clicked)="mail('Estoy interesado en Hogarizarme - Quiero saber más')"></page-link>
        </div>
    </div>
</div>

<!-- HOW -->
<div class="block how" id="how">
    <div class="inner-block">
        <div class="box">
            <div class="title">
                <div class="font-h3">¿Cómo se hogariza?</div>
                <div class="font-h2">Conoce el proceso</div>
            </div>
            <div class="menu" id="how-menu">
                <page-link *ngFor="let l of howLinks; let i = index" [link]="l" (clicked)="howLinkClicked(i)" [id]="'howLink' + i"></page-link>
            </div>
            <div class="content" #content>
                <div class="font-body1 text">{{howSelectedContent.text}}</div>
                <div class="sticker" [ngStyle]="{ 'top' : howSelectedContent.top + 'px', 'right' : howSelectedContent.right + 'px', 
                    'width' : howSelectedContent.width + 'px', 'color' : howSelectedContent.color, 'transform' : howSelectedContent.rotated ? 'rotate(' + howSelectedContent.rotated + 'deg)' :  '' }">
                    <mat-icon [svgIcon]="howSelectedContent.icon" [inline]="true"></mat-icon>
                </div>
            </div>
        </div>
        <div class="box-shape" #boxShape>
            <div class="sticker" [ngStyle]="{ 'bottom' : howSelectedContent.bottomMobile + 'px', 'right' : howSelectedContent.rightMobile + 'px', 
                'width' : howSelectedContent.widthMobile + 'px', 'color' : howSelectedContent.color, 'transform' : howSelectedContent.rotatedMobile ? 'rotate(' + howSelectedContent.rotatedMobile + 'deg)' :  '' }">
                <mat-icon [svgIcon]="howSelectedContent.icon" [inline]="true"></mat-icon>
            </div>
        </div>
    </div>
</div>

<!-- FOR -->
<div class="block for" id="for">
    <div class="inner-block">
        <div class="sticker yellow-pale-stripes">
            <mat-icon svgIcon="stripes" [inline]="true"></mat-icon>
        </div>
        <div class="sticker blue-pale-waves">
            <mat-icon svgIcon="waves" [inline]="true"></mat-icon>
        </div>
        <div class="definition">
            <div class="font-h3">¿Para quién es este servicio?</div>
            <div class="font-h1">Para los que llevan su hogar como bandera</div>
            <div class="font-body2">Hogarizarme es para gente inconformista, para los que adoran el orden, la armonía y la funcionalidad. Para personas que valoran su tiempo y saben que la felicidad hay que aprovecharla. Para los que quieren aprender a organizarse para llegar a todo y vivir cada experiencia en casa de forma única.</div>
        </div>
        <div class="examples" #examples>
            <horizontal-card *ngFor="let c of forCards" [card]="c"></horizontal-card>
        </div>
    </div>
</div>

<!-- COLLAB -->
<div class="block collab" id="collab">
    <div class="inner-block">
        <div class="left">
            <div class="left-inner">
                <div class="font-h3">¡Quiero oir tus ideas!</div>
                <div class="font-h2">¿Se te ocurre otra idea de colaboración?</div>
            </div>
        </div>
        <div class="right">
            <div class="right-inner">
                <div class="font-body2">Escríbeme un mail a <span class="email" (click)="mail('¡Colaboremos!')">marta@hogarizarme.com</span> y me la cuentas, me encantaría oirla!!</div>
                <page-link [link]="collabLink" (click)="mail('¡Colaboremos!')"></page-link>
            </div>
        </div>
        <div class="sticker red-fluff">
            <mat-icon svgIcon="fluff" [inline]="true"></mat-icon>
        </div>
    </div>
</div>

<!-- STORIES -->
<div class="block stories" id="stories" *ngIf="(stories$ | async)?.length > 0">
    <div class="inner-block">
        <div class="font-h3">Inspírate con estas historias de hogares</div>
        <div class="font-h2">Casas que ya se han hogarizado</div>
        <div class="cards">
            <div class="arrows" *ngIf="(stories$ | async)?.length > 4">
                <mat-icon svgIcon="arrow" class="left" [inline]="true" (click)="move(false)"></mat-icon>
                <mat-icon svgIcon="arrow" class="right" [inline]="true" (click)="move(true)"></mat-icon>
            </div>
            <div class="cards-content" #slider>
                <card *ngFor="let h of stories$ | async" [card]="h"></card>
            </div>
        </div>
    </div>
    <div class="sticker blue-pale-stripes">
        <mat-icon svgIcon="stripes" [inline]="true"></mat-icon>
    </div>
</div>

<!-- ME -->
<div class="block me" id="me">
    <img src="assets/images/me-bg.svg" class="bg">
    <div class="inner-block">
        <div class="up">
            <div class="content" [ngStyle]=" { 'margin-top' : (smallScreen) ? ((stories$ | async)?.length > 0 ? '32px' : '48px') : '168px' } ">
                <div class="font-h3">Detrás de Hogarizarme</div>
                <div class="font-h2">Sobre mí</div>
                <div class="font-h4">¡Hola! Mi nombre es Marta. </div>
                <div class="font-body2">
                    Psicóloga por vocación y organizadora por naturaleza. 
                    Me dedico desde hace más de 10 años a la educación y a las familias desde distintos enfoques (formación de padres, orientación educativa, psicopedagogía, psicomotricidad libre y actividades de acción social y tiempo libre).
                    En los últimos años me he formado en diseño de Interiores y organización profesional para poder combinar mis dos pasiones y crear Hogarizarme.
                </div>
            </div>
            <img src="assets/images/marta1.jpg" class="image">
        </div>
        <div class="down">
            <img src="assets/images/marta2.jpg" class="image">
            <div class="font-body2">
                Además de mi faceta profesional soy mamá de Cristina, y parte de una familia que espera seguir creciendo. 
                Si hay algo que me caracteriza es poner mucha pasión en lo que hago y no conformarme con resultados aceptables. 
                Para mi la clave de un buen trabajo es la escucha, la coherencia y por supuesto: La creatividad.
            </div>
        </div>
    </div>
</div>

<!-- CONTACT -->
<div class="block contact" id="contact">
    <div class="inner-block">
        <div class="left">
            <div class="left-inner">
                <div class="font-h3">¿Pensando en hogarizar tu casa?</div>
                <div class="font-h2">Escríbeme y charlamos</div>
            </div>
        </div>
        <div class="right">
            <div class="right-inner">
                <div class="font-body2">Puedes escribirme un mail a <span class="email" (click)="mail('Necesito asesoramiento para hogarizar mi casa')">marta@hogarizarme.com</span> o contestar el siguiente formulario y ya me encargo yo de contactarte</div>
                <div class="contact-button">
                    <page-button text="Ir al form" (click)="goForm()"></page-button>
                </div>
            </div>
        </div>
        <div class="sticker blue-waves">
            <mat-icon svgIcon="waves" [inline]="true"></mat-icon>
        </div>
    </div>
</div>

<!-- FOOTER -->
<page-footer></page-footer>