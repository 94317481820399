import { Injectable, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private dialog: MatDialog
    ) { }

    public showCardModal(card: Card) {
        return this.dialog.open(DialogCardModal, {
            data: { card },
            panelClass: 'dialog-container'
        })
    }

}

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Card } from 'src/app/shared/models/card.model';

@Component({
    templateUrl: './dialog.card-modal.html',
    styleUrls: ['./dialog.card-modal.scss']
})
export class DialogCardModal {

    constructor(
        private dialog                          : MatDialogRef<DialogCardModal>,
        @Inject(MAT_DIALOG_DATA) public data    : any
    ) { }

    close() {
        this.dialog.close()
    }

}