import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Constants } from 'src/environments/constants';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavLinkComponent } from './shared/components/nav-link/nav-link.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { LinkComponent } from './shared/components/link/link.component';
import { CardComponent } from './shared/components/card/card.component';
import { HorizontalCardComponent } from './shared/components/horizontal-card/horizontal-card.component';
import { FormComponent } from './pages/form/form.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { TextAreaComponent } from './shared/components/text-area/text-area.component';
import { TextInputComponent } from './shared/components/text-input/text-input.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NavLinkComponent,
    ButtonComponent,
    LinkComponent,
    CardComponent,
    HorizontalCardComponent,
    FormComponent,
    CheckboxComponent,
    TextAreaComponent,
    TextInputComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(Constants.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [

  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
