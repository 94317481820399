import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'page-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() public text: string
  @Input() public blue: boolean
  @Input() public secondary: boolean
  @Input() public disabled: boolean

  constructor() { }

  public enable(valid: boolean) {
    this.disabled = !valid
  }

}
