import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent {

  @Input() public input: string
  @Input() public placeholder: string

  @Input() public type: string

  @Output() public onInput = new EventEmitter<string>()
  @Output() public onEnter = new EventEmitter()

  constructor() { }
}
