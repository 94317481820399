import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { slideInAnimation } from './core/utils/animation/route.animation';

@Component({
  selector: 'page-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})

export class AppComponent {

  constructor(
    private matIconRegistry   : MatIconRegistry,
    private domSanitizer      : DomSanitizer,
    private renderer          : Renderer2
  ) {
    this.setupSvgIcons()
  }

  ngOnInit() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }

  public svgIcons: Array<string> = [
    'arrow',
    'burger',
    'check',
    'close',
    'facebook',
    'fluff',
    'full-logo',
    'instagram',
    'logo',
    'pinterest',
    'stripes',
    'waves'
  ]

  private setupSvgIcons() {
    this.svgIcons.forEach(name => {
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/' + name + '.svg')
      )
    })
  }

}
