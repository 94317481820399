import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Constants } from 'src/environments/constants';
import { FormComponent } from './pages/form/form.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  // Form
  {
    path: Constants.routes.form,
    component: FormComponent,
    data: {animation: 'Form'}
  },
  // Home
  {
    path: Constants.routes.home, 
    component: HomeComponent,
    data: {animation: 'Home'}
  },
  {
    path: '', 
    component: HomeComponent,
    data: {animation: 'Home'}
  },
  {
    path: '**', 
    redirectTo: Constants.routes.home, 
    pathMatch: 'full'
  }
]

RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled'
})

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
