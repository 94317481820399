import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {

  @Input() public input: string

  @Input() public placeholder: string
  @Input() public width: string
  @Input() public height: string

  @Output() public onInput = new EventEmitter<string>()

  constructor() { }

}
