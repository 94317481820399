import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input() public text: string
  @Input() public subtext: string
  @Input() public active: boolean

  @Output() public clicked = new EventEmitter<boolean>()

  constructor() { }

  public swap(active: boolean) {
    this.active = active
  }

  public getClicked() {
    this.clicked.emit(this.active)
  }

}
