import { Component, HostListener, Input, EventEmitter, Output, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Link } from '../../models/link.model';

@Component({
  selector: 'page-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {

  @Input() public link: Link

  @Output() public clicked = new EventEmitter()

  constructor() { }

  @HostListener('click', ['$event'])
  onClick(link: any) {
    this.clicked.emit(link)
  }

}
