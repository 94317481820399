<div class="container">
    <div class="scroller" #scroller>
        <mat-icon svgIcon="close" class="close" (click)="goHome()"></mat-icon>
        <div class="box" [ngStyle]="{ 'align-items' : slide < 8 ? 'unset' : 'center', 'min-height' : smallScreen && slide == 12 ? 'calc(100vh - 186px)' : 'unset', 'justify-content' : smallScreen && slide == 12 ? 'center' : 'unset'}">
            <div class="upper" *ngIf="slide < 8">
                <div class="font-h3 slides">{{slide}}/{{total}}</div>
                <div class="content">
                    <div class="explanation">
                        <div class="font-h4" [ngStyle]="{ 'width' : slide === 5 ? '352px' : '458px'}">{{explanation[slide - 1]}}</div>
                        <div class="font-body2" *ngIf="[2,4,5,7].includes(slide)">Puedes seleccionar más de una opción</div>
                    </div>
                    <div class="responses"[ngSwitch]="slide">
                        <div *ngSwitchCase="1">
                            <checkbox class="input" *ngFor="let e of whoLiveWith; let i = index" [text]="e" (clicked)="check(0, i)" #cb1></checkbox>
                            <text-area placeholder="Si tu caso no es ninguno de estos, escribe aquí cómo vives tú" [input]="wasInput(0)"
                                [width]="400" [height]="112" (onInput)="clearCheckboxGroup(0, $event)" #ta1></text-area>
                        </div>
                        <div *ngSwitchCase="2" [ngClass]="{ 'two-columns' : !smallScreen }">
                            <checkbox class="input" *ngFor="let e of whichRoom; let i = index" [text]="e[0]" [subtext]="e[1]" (clicked)="check(1, i, true)" #cb2></checkbox>
                            <text-area placeholder="Si tu caso no es ninguno de estos, escribe qué quieres Hogarizar" [input]="wasInput(1)"
                                [width]="352" [height]="112" (onInput)="clearCheckboxGroup(1, $event, true)"></text-area>
                        </div>
                        <div *ngSwitchCase="3">
                            <text-area placeholder="Puedes contarmelo aquí" [input]="wasInput(2)"
                                [width]="400" [height]="200" (onInput)="clearCheckboxGroup(2, $event)" style="margin-top: 0 !important;"></text-area>
                        </div>
                        <div *ngSwitchCase="4" [ngClass]="{ 'scroll' : !smallScreen }">
                            <checkbox class="input" *ngFor="let e of whoYouAre; let i = index" [text]="e" (clicked)="check(3, i, true)" #cb4></checkbox>
                        </div>
                        <div *ngSwitchCase="5" [ngClass]="{ 'two-columns' : !smallScreen }">
                            <checkbox class="input" *ngFor="let e of whatToDo; let i = index" [text]="e[0]" [subtext]="e[1]" (clicked)="check(4, i, true)" #cb5></checkbox>
                        </div>
                        <div *ngSwitchCase="6">
                            <checkbox class="input" *ngFor="let e of howToDo; let i = index" [text]="e" (clicked)="check(5, i)" #cb6></checkbox>
                        </div>
                        <div *ngSwitchCase="7">
                            <checkbox class="input" *ngFor="let e of whenToDo; let i = index" [text]="e" (clicked)="check(6, i)" #cb7></checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="upper" *ngIf="slide > 7" [ngClass]="{ 'vertical-centered' : slide === 12 }">
                <div class="explanation centered" *ngIf="slide < 12" style="margin-top: 24px">
                    <div class="font-h4">¡Casi estamos! Antes de acabar, me gustaría saber un par de cosas sobre ti, para poder comunicarme contigo</div>
                </div>
                <div class="explanation centered" *ngIf="slide === 12">
                    <div class="font-h4">¡Todo listo! Voy a leerme tu cuestionario y me pondré en contacto contigo. ¡Qué ganas de hogarizar tu casa!</div>
                </div>
                <div class="responses" style="display: flex; flex-direction: column;" *ngIf="slide < 12">
                    <div class="font-body3 input-label" *ngIf="slide === 8">¿Cómo te llamas?</div>
                    <text-input type="text" placeholder="Escribe aquí tu nombre" (onInput)="input(7, $event)" [input]="name"></text-input>
                    <div class="font-body3 input-label" *ngIf="slide === 9">¿Cuál es tu número de teléfono?</div>
                    <text-input type="phone" placeholder="Por si prefieres hablar por teléfono" *ngIf="slide > 8" (onInput)="input(8, $event)" [input]="phone"></text-input>
                    <div class="font-body3 input-label"  *ngIf="slide === 10">¿Cuál es tu correo electrónico?</div>
                    <text-input type="text" placeholder="Para enviarte tus respuestas del formulario" *ngIf="slide > 9" (onInput)="input(9, $event)" [input]="email"></text-input>
                    <div class="font-body3 input-label"  *ngIf="slide === 11">¿Dónde vives? (pueblo, ciudad, etc.)</div>
                    <text-input type="text" placeholder="Para saber cómo podemos trabajar" *ngIf="slide > 10" (onInput)="input(10, $event)" [input]="location"></text-input>
                </div>
            </div>
            <div class="buttons">
                <div class="previous-button" *ngIf="slide > 1 && slide < 12">
                    <page-button text="Anterior" [secondary]="true" (click)="move(false)"></page-button>
                </div>
                <div class="continue-button" *ngIf="slide < 12" [ngStyle]="{ 'width' : (slide > 1) ? ((slide > 7) ? '280px' : '338px') : '352px'}">
                    <page-button text="Continuar" [blue]="true" [disabled]="disabled" (click)="move(true)"></page-button>
                </div>
                <div class="finish-button" *ngIf="slide === 12">
                    <page-button text="Terminar" [blue]="true" (click)="goHome()"></page-button>
                </div>
            </div>
        </div>
    </div>
    <div class="sticker yellow-stripes">
        <mat-icon svgIcon="stripes" [inline]="true"></mat-icon>
    </div>
</div>