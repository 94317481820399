import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Card } from 'src/app/shared/models/card.model';
import { Constants } from 'src/environments/constants';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StoryService {

    private stories$: BehaviorSubject<Array<Card>> = new BehaviorSubject([])

    constructor(
        private afs     : AngularFirestore
    ) { 
        afs.collection<Card>(Constants.collections.stories).valueChanges().subscribe(this.stories$)
    }

    public someStory(): Observable<boolean> {
        return this.stories$.pipe(map(stories => stories.length > 0))
      }

    public stories(): Observable<Array<Card>> {
        return this.stories$.asObservable()
    }

}