import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { TextAreaComponent } from 'src/app/shared/components/text-area/text-area.component';
import { Constants } from 'src/environments/constants';

@Component({
  selector: 'page-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  public disabled: boolean = true

  public slide: number = 1
  public total: number = 7

  public explanation: Array<string> = [
    'Este formulario tiene como objetivo que me cuentes tu situación para poder ofrecerte la mejor ayuda posible. Para empezar, cuéntame cómo vives',
    '¿Qué te gustaría Hogarizar?',
    '¿Qué te ha llevado a tomar la decisión de contactar con un organizador profesional?',
    'Señala aquellas frases con las que te identifiques',
    '¿Cuál de estas opciones te interesaría llevar a cabo?',
    '¿Quieres realizar el proceso asesorado, acompañado o dirigido?',
    '¿Qué disponibilidad y preferencia de horario tienes para llevar a cabo el proceso?'
  ]

  public responses: Array<Array<string>> = new Array

  public whoLiveWith: Array<string> = [
    'Vivo solo',
    'Vivo con mi pareja',
    'Vivo con mi familia (un hijo o más)',
    'Vivo con mi familia (con mis padres)'
  ]

  public whichRoom: Array<Array<string>> = [
    ['Cocina', 'Lavadero, Almacenaje, despensa, material de limpieza, distribuición'],
    ['Dormitorio', 'Vestidor, Armarios, Distribuición'],
    ['Habitaciones infantiles', 'Ropa, jueguetes, espacio de juego o distribuición'],
    ['Zona de trabajo / Despacho', 'Documentos, material, distribuición'],
    ['Salón', 'Almacenaje, distribución'],
    ['Trastero', 'Almacenaje, distribución']
  ]

  public whoYouAre: Array<string> = [
    'Mi casa es demasiado pequeña',
    'Me gusta ordenar',
    'Me horroriza ordenar',
    'Tengo mucha ropa',
    'Las tareas del hogar me horrorizan',
    'No me preocupa la limpieza de la casa',
    'Tengo muchas cosas que no utilizo',
    'Me gusta guardar cosas de recuerdo',
    'Tengo poco espacio de almacenaje',
    'Si mi casa fuera más grande, sería más ordenado/a',
    'No tengo demasiado tiempo para dedicarle a la casa',
    'Me gusta dedicar tiempo a mi casa',
    'Me preocupa la limpieza de mi casa',
    'Es más importante el orden que la limpieza',
    'Es más importante la limpieza que el orden'
  ]

  public whatToDo: Array<Array<string>> = [
    ['Distribución del espacio', 'Qué mueble encaja mejor en el espacio'],
    ['Organización del almacenaje', 'Qué contenido se guarda en qué sitio y cómo almacenar y mantener el orden'],
    ['Hábito de orden y organización.', 'Aprender a crear un sistema de orden en el hogar, y aprender a mantenerlo.'],
    ['Mobiliario', '¿Quieres que nos adaptemos al mobiliario disponible o es posible valorar otras opciones?'],
    ['Revisión del contenido', 'Revisar la ropa, objetos, utensilios...']
  ]

  public howToDo: Array<string> = [
    'Asesorado, prefiero estar solo/a',
    'Acompañado, mejor con alguien para avanzar más rápido',
    'Dirigido, prefiero que me lo hagan y ver directamente el resultado final'
  ]

  public whenToDo: Array<string> = [
    'Mañanas',
    'Tardes',
    'Fines de semana'
  ]

  @ViewChildren('cb1') cb1Ref: QueryList<CheckboxComponent>
  @ViewChildren('cb2') cb2Ref: QueryList<CheckboxComponent>
  @ViewChildren('cb4') cb4Ref: QueryList<CheckboxComponent>
  @ViewChildren('cb5') cb5Ref: QueryList<CheckboxComponent>
  @ViewChildren('cb6') cb6Ref: QueryList<CheckboxComponent>
  @ViewChildren('cb7') cb7Ref: QueryList<CheckboxComponent>

  @ViewChild('ta1') ta1Ref: TextAreaComponent

  constructor(
    private router    : Router,
    private cdref     : ChangeDetectorRef,
    private functions : AngularFireFunctions
  ) { }

  public smallScreen: boolean

  ngOnInit() {
    this.smallScreen = window.screen.width < 1300
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }

  public goHome() {
    this.router.navigate([Constants.routes.home])
  }

  public getGroup(group: number) {
    let groupRef: QueryList<CheckboxComponent>
    switch(group) {
      case 0:
        groupRef = this.cb1Ref
        break
      case 1:
        groupRef = this.cb2Ref
        break
      case 2:
        break
      case 3:
        groupRef = this.cb4Ref
        break
      case 4:
        groupRef = this.cb5Ref
        break
      case 5:
        groupRef = this.cb6Ref
        break
      case 6:
        groupRef = this.cb7Ref
        break
      default:
        break
    }
    return groupRef
  }
  
  public getOther(group: number) {
    let otherRef: TextAreaComponent
    switch(group) {
      case 0:
        otherRef = this.ta1Ref
        break
      default:
        break
    }
    return otherRef
  }

  public check(group: number, checkbox: number, multi?: boolean) {
    this.getGroup(group).forEach((e, i) => {
      if (i === checkbox) {
        if (!e.active) {
          this.disabled = false
          e.swap(true)
          if (multi) {
            if (!!!this.responses[group]) this.responses[group] = new Array
            if (this.responses[group].indexOf(e.text) === -1) {
              this.responses[group].unshift(e.text)
            }
          }
          else {
            this.responses[group] = [e.text]
          }
        }
        else {
          e.swap(false)
          if (multi) {
            if (!!!this.responses[group]) this.responses[group] = new Array
            let index = this.responses[group].indexOf(e.text)
            this.responses[group].splice(index, 1)
            this.disabled = this.responses[group].length === 0
          }
          else if (this.getOther(group).input.length !== 0) {
            this.responses[group] = [this.getOther(group).input]
          }
          else {
            this.disabled = true
            this.responses[group] = []
          }
        }
      }
      else if (!multi) {
        e.swap(false)
      }
    })
  }

  private lastInput: string = ''
  public clearCheckboxGroup(group: number, input: string, multi?: boolean) {
    if (multi) {
      if (!!!this.responses[group]) this.responses[group] = new Array
      if (input) {
        this.disabled = false
        if (input.length === 1 && this.lastInput < input) this.responses[group].push(input)
        else this.responses[group][this.responses[group].length - 1] = input
      }
      else {
        this.disabled = true
        this.responses[group].pop()
      }
    }
    else {
      if (input) {
        this.disabled = false
        this.responses[group] = [input]
      }
      else {
        this.disabled = true
        this.responses[group] = []
      }
      if (this.getGroup(group)) {
        this.getGroup(group).forEach(e => {
          e.swap(false)
        })
      }
    }
    this.lastInput = input
  }

  @ViewChild('scroller') scrollerRef: ElementRef

  public move(forward: boolean) {
    if (this.scrollerRef)
      setTimeout(_ => this.scrollerRef.nativeElement.scrollTop = 0 , 0)
    if (forward) {
      this.slide += 1     
      if (this.slide - 1 < 8) {
        this.disabled = true
      }
      else if (this.slide - 1 < 11) {
        switch (this.slide - 1) {
          case 7:
            this.disabled = !!!this.name
            break
          case 8:
            this.disabled = !!!this.phone
            break
          case 9:
            this.disabled = !!!this.email
            break
          case 10:
            this.disabled = !!!this.location
            break
        }
      }
      else {
        this.disabled = false
        this.send()
      }
    }
    else {
      this.slide -= 1
      this.disabled = false
    }
    if (this.responses[this.slide - 1]) {
      setTimeout(_ => {
        if (this.slide - 1 < 8) {
          let groupRef = this.getGroup(this.slide - 1)
          if (groupRef) {
            this.disabled = false
            let groupArray = groupRef.map(e => e.text)
            groupRef.forEach((e, i) => e.swap(this.responses[this.slide - 1].includes(groupArray[i])))
          }
        }
      }, 10)
    }
  }

  public wasInput(group: number) {
    let input = ''
    if (!this.responses[group]) 
      return input
    
    let last = this.responses[group][this.responses[group].length - 1]
    if (!this.getGroup(group)) {
      input = last
    }
    else if (this.responses.length > 0) {
      let groupArray = this.getGroup(group).map(e => e.text)
      input = groupArray.includes(last) ? '' : last
    }
    if (input?.length > 0) this.disabled = false
    return input
  }

  public name: string
  public phone: string
  public email: string
  public location: string

  public input(group: number, text: string) {
    this.disabled = !!!text
    this.responses[group] = [text]
    switch (group) {
      case 7:
        this.name = text
        break
      case 8:
        this.phone = text
        break
      case 9:
        this.email = text
        break
      case 10:
        this.location = text
        break
    }
  }

  public send() {
    const fields = {
      name: this.name,
      phone: this.phone,
      email: this.email,
      location: this.location,
      responses: this.responses
    }
    console.log(fields)

    this.functions.httpsCallable(Constants.functions.sendForm)({
      fields: fields
    }).toPromise()
  }

}
