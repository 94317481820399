import { Component, Input, OnInit } from '@angular/core';
import { NavLink } from '../../models/nav-link.model';

@Component({
  selector: 'nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent {

  @Input() public navLink: NavLink

  constructor() { }

  public scrollTo() {
    document.getElementById(this.navLink.link).scrollIntoView({
      behavior: 'smooth',
      block: this.navLink.where
    })
  }

}
