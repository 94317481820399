<div class="footer-container">
    <div class="partners">
        <img src="assets/images/academiadelorden.jpg">
    </div>
    <mat-icon class="logo" svgIcon="logo" [inline]="true"></mat-icon>
    <div class="separator"></div>
    <div class="social">
        <mat-icon svgIcon="pinterest" [inline]="true" (click)="open('https://pin.it/76uoYqP')"></mat-icon>
        <mat-icon svgIcon="instagram" [inline]="true" (click)="open('https://www.instagram.com/hogarizarme/')"></mat-icon>
        <mat-icon svgIcon="facebook" [inline]="true" (click)="open('https://www.facebook.com/hogarizarme/')"></mat-icon>
    </div>
    <div class="partners2">
        <img src="assets/images/academiadelorden.jpg">
    </div>
</div>