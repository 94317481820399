import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { constants } from 'buffer';
import { BehaviorSubject } from 'rxjs';
import { StoryService } from 'src/app/core/story/story.service';
import { Card } from 'src/app/shared/models/card.model';
import { HorizontalCard } from 'src/app/shared/models/horizontal-card.model';
import { Link } from 'src/app/shared/models/link.model';
import { Constants } from 'src/environments/constants';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public stories$: BehaviorSubject<Array<Card>> = new BehaviorSubject([])

  constructor(
    private renderer    : Renderer2,
    private router      : Router,
    public storyService : StoryService
  ) {
    this.storyService.stories().subscribe(this.stories$)
  }

  @ViewChild('upButton') upButtonRef: ElementRef

  @HostListener('window:touchmove', ['$event'])
  public onTouchMove(e) {
    if (document.body.scrollTop > 100) {
      this.renderer.setStyle(this.upButtonRef.nativeElement, 'opacity', '1')
    }
    else {
      this.renderer.setStyle(this.upButtonRef.nativeElement, 'opacity', '0')
    }
  }

  public up() {
    if (window.screen.width < 414) {
      document.body.scrollTo({
        top: 0, 
        behavior: 'smooth'
      })
      this.renderer.setStyle(this.upButtonRef.nativeElement, 'opacity', '0')
    }
    else {
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      })
    }
  }

  public smallScreen: boolean

  ngOnInit() {
    this.smallScreen = window.screen.width < 1300
  }

  public whatLink: Link = {
    name: 'Quiero saber más'
  }

  public whatCards: Array<Card> = [
    {
      image: 'what-card-1.jpg',
      title: 'Espacios y tareas del hogar',
      text: 'Accesibilidad para tareas del hogar y material de limpieza.'
    },
    {
      image: 'what-card-2.jpg',
      title: 'Vestidores',
      text: 'Que tu armario sea funcional, y organizado de manera que se optimice el espacio al máximo.'
    },
    {
      image: 'what-card-3.jpg',
      title: 'Casas con niños',
      text: 'Cómo adaptar la casa en la que van a vivir niños sin que pierda orden, funcionalidad y estilo, sin ser un riesgo para ellos y un estrés para los mayores.'
    },
    {
      image: 'what-card-4.jpg',
      title: 'Cocinas',
      text: 'Organización de almacenaje y el cocinado, limpieza y comedor.'
    },
    {
      image: 'what-card-5.jpg',
      title: 'Habitaciones infantiles',
      text: 'Adaptar su habitación a las necesidades evolutivas sin cambiar de mobiliario. Espacio de juego, almacenaje, y otras necesidades infantiles. Paso a una habitación para niños o adolescentes. '
    }
  ]

  public howLinks: Array<Link> = [
    {
      name: '1. Conocer',
      big: true,
      active: true
    },
    {
      name: '2. Valorar',
      big: true
    },
    {
      name: '3. Actuar',
      big: true
    },
    {
      name: '4. Disfrutar',
      big: true
    }
  ]

  public howContent: Array<{}> = [
    {
      text: 'Necesito saber quién eres, cuáles son tus circunstancias y necesidades, qué esperas de Hogarizarme y de qué aspectos no nos podemos olvidar.',
      icon: 'stripes',
      color: '#F5DD96',
      width: 242,
      top: 20,
      right: 16,
      widthMobile: 101,
      bottomMobile: -29,
      rightMobile: -12
    },
    {
      text: 'Tras conocer tus necesidades y circunstancias tengo que ver el espacio y los recursos disponibles para poder ofrecerte un plan de acción que se te ajuste a la perfección y te dé ganas de ponerte en marcha ¡ya!',
      icon: 'waves',
      color: '#569211',
      width: 240,
      top: 247,
      right: 24,
      widthMobile: 91,
      bottomMobile: -17,
      rightMobile: -18
    },
    {
      text: 'Quiero ofrecerte un proyecto que sea único y especial para ti, con las herramientas y soluciones ajustadas y en el tiempo justo. ¡Que la vida es demasiado bonita para malgastarla!',
      icon: 'fluff',
      color: '#FF3D1F',
      width: 224,
      top: 134,
      right: 48,
      widthMobile: 91,
      bottomMobile: -24,
      rightMobile: -24
    },
    {
      text: 'Cuando terminemos todo el trabajo ya solo quedará que estés orgulloso/a y satisfecho/a del trabajo realizado y puedas vivir tu espacio con alegría. ¡Que disfrutes tu nuevo hogar!',
      icon: 'logo',
      color: '#115892',
      width: 164,
      top: 16,
      right: 44,
      rotated: 10,
      widthMobile: 72,
      bottomMobile: -60,
      rightMobile: -2,
      rotatedMobile: 10
    }
  ]

  @ViewChild('content') contentRef: ElementRef
  @ViewChild('boxShape') boxShapeRef: ElementRef

  public howSelectedContent: any = this.howContent[0]

  public howLinkClicked(index: number) {

    if (this.smallScreen) {
      let el = document.getElementById(`howLink${index}`)
      
      if (index != 3)
        el.scrollIntoView({ block: 'nearest', inline: 'center' })
      else
        document.getElementById('how-menu').scrollTo({left: 1000})
    }

    this.renderer.setStyle(this.contentRef.nativeElement, 'opacity', '0')
    if (this.boxShapeRef) this.renderer.setStyle(this.boxShapeRef.nativeElement, 'opacity', '0')
    setTimeout(_ => {
      this.renderer.setStyle(this.contentRef.nativeElement, 'opacity', '1')
      if (this.boxShapeRef) this.renderer.setStyle(this.boxShapeRef.nativeElement, 'opacity', '1')
      this.howSelectedContent = this.howContent[index]
    }, 250)

    this.howLinks.forEach((v, i) => {
      if (i === index) {
        v.active = true
      }
      else {
        v.active = false
      }
    })
  }

  public forCards: Array<HorizontalCard> = [
    {
      image: 'for-card-1.jpg',
      title: 'Nuevos comienzos',
      text: 'Si vamos a emprender, aprender o simplemente a actuar de otra manera, nuestro espacio debe reflejarlo. Cuando algo cambia en nosotros ¡SE NOTA!'
    },
    {
      image: 'for-card-2.jpg',
      title: 'Retos importantes',
      text: 'Pisos compartidos, casas con muchos niños (o niños por primera vez), pisos diminutos para amantes de la ropa…<br>Que el espacio tenga el protagonismo justo para que la aventura sea un éxito que dependa sólo de nosotros.',
      reverse: true
    },
    {
      image: 'for-card-3.jpg',
      title: 'Bienvenidas y despedidas',
      text: 'Es ley de vida, pero nadie nos enseña cómo tratar los espacios si esto sucede. Desde la alegría con la llegada de un bebé o el pánico a la arrasadora adolescencia, hasta la triste despedida de un ser querido cuya historia queda aún escrita en cada rincón…',
      reverse: true
    },
    {
      image: 'for-card-4.jpg',
      title: 'Para vivir mejor',
      text: 'Porque no hace falta esperar a que algo ocurra para atreverse a cambiar. Renueva el armario o imprímele un aire nuevo a algo que está bien, pero podría estar mejor. '
    }
  ]

  private direction: boolean = true
  private doingHorizontal: boolean = false
  
  private VERTICAL_BREAK_POINT = 2864
  private HORIZONTAL_INITIAL_POINT = 0
  private HORIZONTAL_SCROLL_FORCE = 65

  @ViewChild('examples') examplesRef: ElementRef

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    if (document.documentElement.scrollTop > 100) {
      this.renderer.setStyle(this.upButtonRef.nativeElement, 'opacity', '1')
    }
    else {
      this.renderer.setStyle(this.upButtonRef.nativeElement, 'opacity', '0')
    }

    if (this.smallScreen) return

    let vertical = document.documentElement.scrollTop
    let horizontal = this.examplesRef.nativeElement.scrollLeft

    const HORIZONTAL_FINAL_POINT = this.examplesRef.nativeElement.scrollWidth - this.examplesRef.nativeElement.clientWidth

    if ((this.direction && vertical >= this.VERTICAL_BREAK_POINT && horizontal !== HORIZONTAL_FINAL_POINT) || 
    (!this.direction && vertical <= this.VERTICAL_BREAK_POINT && horizontal !== this.HORIZONTAL_INITIAL_POINT)) {
      this.doingHorizontal = true
      document.documentElement.scrollTop = this.VERTICAL_BREAK_POINT
      this.renderer.setStyle(document.documentElement, 'overflow-y', 'hidden')
    }
  }

  @HostListener('window:wheel', ['$event'])
  onWheel(e) {
    if (this.smallScreen) return

    this.direction = e.deltaY > 0

    let horizontal = this.examplesRef.nativeElement.scrollLeft

    const HORIZONTAL_FINAL_POINT = this.examplesRef.nativeElement.scrollWidth - this.examplesRef.nativeElement.clientWidth

    if (this.doingHorizontal) {
      this.examplesRef.nativeElement.scrollLeft += (this.direction ? 1 : -1) * this.HORIZONTAL_SCROLL_FORCE

      horizontal = this.examplesRef.nativeElement.scrollLeft
      this.doingHorizontal = horizontal !== this.HORIZONTAL_INITIAL_POINT && horizontal !== HORIZONTAL_FINAL_POINT

      if (!this.doingHorizontal) {
        this.renderer.setStyle(document.documentElement, 'overflow-y', 'initial')
      }
    }

  }

  public collabLink: Link = {
    name: 'Contáctame'
  }

  @ViewChild('slider', { read: ElementRef}) slider: ElementRef<any>
  private moving: boolean

  public move(right: boolean) {
    const MOVE = 424

    if (!this.moving) {
      this.moving = true
      this.slider.nativeElement.scrollLeft += ((right) ? 1 : -1) * MOVE
      setTimeout(_ => {
        this.moving = false
      }, 400)
    }
  }

  public mail(subject: string) {
    document.location.href = `mailto:${Constants.mail}?subject=${subject}`
  }

  public goForm() {
    this.router.navigate([Constants.routes.form])
  }


}
