import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoryService } from 'src/app/core/story/story.service';
import { Constants } from 'src/environments/constants';
import { NavLink } from '../../models/nav-link.model';

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private router        : Router,
    private storyService  : StoryService
  ) { 
    this.storyService.someStory().subscribe(some => this.navLinks[2].disable = !some)
  }

  public navLinks: Array<NavLink> = [
    {
      name: 'El proceso',
      link: 'how',
      where: 'center'
    },
    {
      name: 'Para quién',
      link: 'for',
      where: 'start'
    },
    {
      name: 'Casos de éxito',
      link: 'stories',
      where: 'start'
    },
    {
      name: 'Sobre mí',
      link: 'me',
      where: 'start'
    },
  ]

  goForm() {
    this.router.navigate([Constants.routes.form])
  }

}
