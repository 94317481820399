import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/utils/dialog/dialog.service';
import { Card } from '../../models/card.model';
import { Link } from '../../models/link.model';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() public card: Card

  constructor(
    private dialog : DialogService
  ) { }

  public seeMoreLink: Link = {
    name: 'Ver la historia entera'
  }

  public showModal() {
    this.dialog.showCardModal(this.card)
  }

}
