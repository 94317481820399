import { Component, Input, OnInit } from '@angular/core';
import { HorizontalCard } from '../../models/horizontal-card.model';

@Component({
  selector: 'horizontal-card',
  templateUrl: './horizontal-card.component.html',
  styleUrls: ['./horizontal-card.component.scss']
})
export class HorizontalCardComponent {

  @Input() public card: HorizontalCard

  constructor() { }

}
